import {
  faBuildingNgo,
  faCameraCctv,
  faCartShopping,
  faCashRegister,
  faChurch,
  faCoffinCross,
  faCoins,
  faDropletDegree,
  faDryer,
  faFerrisWheel,
  faFilePlusMinus,
  faGasPump,
  faGraduationCap,
  faHandHoldingDollar,
  faHouseBuilding,
  faHouseCrack,
  faKey,
  faLightbulb,
  faLightbulbOn,
  faMobile,
  faMoneyBillWave,
  faNetworkWired,
  faNewspaper,
  faPhone,
  faPlaneCircleCheck,
  faSatelliteDish,
  faScreenUsers,
  faSignHanging,
  faSuitcaseMedical,
  faTaxi,
  faTicket,
  faTvRetro,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface IconServices {
  iconName: string
}
export default function IconServices({ iconName }: IconServices) {
  const renderIcon: Record<string, React.ReactNode> = {
    RECARGAS_MOVILES: <FontAwesomeIcon icon={faMobile} className="text-xs" />,
    DIRECTV: <FontAwesomeIcon icon={faSatelliteDish} className="text-xs" />,
    SERVICIOS_PUBLICOS: (
      <FontAwesomeIcon icon={faLightbulb} className="text-xs" />
    ),
    ACUEDUCTO: <FontAwesomeIcon icon={faDropletDegree} className="text-xs" />,
    ASEO: <FontAwesomeIcon icon={faDryer} className="text-xs" />,
    CAJAS_DE_COMPENSACION: (
      <FontAwesomeIcon icon={faFerrisWheel} className="text-xs" />
    ),
    COMERCIOS_DISTRIBUIDORAS: (
      <FontAwesomeIcon icon={faCartShopping} className="text-xs" />
    ),
    CONJUNTOS_RESIDENCIALES: (
      <FontAwesomeIcon icon={faHouseBuilding} className="text-xs" />
    ),
    COOPERATIVAS: <FontAwesomeIcon icon={faScreenUsers} className="text-xs" />,
    ENTIDADES_GUBERNAMENTALES: (
      <FontAwesomeIcon icon={faBuildingNgo} className="text-xs" />
    ),
    FONDOS_FUNDACIONES: (
      <FontAwesomeIcon icon={faHandHoldingDollar} className="text-xs" />
    ),
    FUNERARIAS: <FontAwesomeIcon icon={faCoffinCross} className="text-xs" />,
    GAS: <FontAwesomeIcon icon={faGasPump} className="text-xs" />,
    GOBIERNO_ESTADO: (
      <FontAwesomeIcon icon={faBuildingNgo} className="text-xs" />
    ),
    IGLESIAS: <FontAwesomeIcon icon={faChurch} className="text-xs" />,
    IMPUESTOS: <FontAwesomeIcon icon={faMoneyBillWave} className="text-xs" />,
    INMOBILIARIA: <FontAwesomeIcon icon={faSignHanging} className="text-xs" />,
    INSTITUCIONES_EDUCATIVAS_COLEGIOS: (
      <FontAwesomeIcon icon={faGraduationCap} className="text-xs" />
    ),
    LOTERIAS: <FontAwesomeIcon icon={faTicket} className="text-xs" />,
    ENERGIA: <FontAwesomeIcon icon={faLightbulbOn} className="text-xs" />,
    MULTISERVICIOS: (
      <FontAwesomeIcon icon={faFilePlusMinus} className="text-xs" />
    ),
    OTROS: <FontAwesomeIcon icon={faNetworkWired} className="text-xs" />,
    PERIODICOS_REVISTAS: (
      <FontAwesomeIcon icon={faNewspaper} className="text-xs" />
    ),

    SALUD_MEDICINA_PREPAGADA_PILA: (
      <FontAwesomeIcon icon={faSuitcaseMedical} className="text-xs" />
    ),
    SEGUROS: <FontAwesomeIcon icon={faHouseCrack} className="text-xs" />,
    SERVICIO_DE_TRANSPORTE: (
      <FontAwesomeIcon icon={faTaxi} className="text-xs" />
    ),
    SERVICIOS_FINANCIEROS: (
      <FontAwesomeIcon icon={faCoins} className="text-xs" />
    ),
    SERVICIOS_PRIVADOS: <FontAwesomeIcon icon={faKey} className="text-xs" />,
    TELEFONIA_FIJA: <FontAwesomeIcon icon={faPhone} className="text-xs" />,
    TELEFONIA_MOVIL_TV_POR_SUSCRIPCION: (
      <FontAwesomeIcon icon={faTvRetro} className="text-xs" />
    ),
    AEROLINEAS_Y_AGENCIAS_DE_VIAJES: (
      <FontAwesomeIcon icon={faPlaneCircleCheck} className="text-xs" />
    ),
    VALORES_Y_VIGILANCIA: (
      <FontAwesomeIcon icon={faCameraCctv} className="text-xs" />
    ),
    CAMARA_DE_COMERCIO: (
      <FontAwesomeIcon icon={faCashRegister} className="text-xs" />
    ),
  }
  return <div>{renderIcon[iconName]}</div> || null
}
