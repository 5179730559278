/* eslint-disable tailwindcss/no-custom-classname */
import { useState } from 'react'
import Image from 'next/image'

import clsx from 'clsx'
import { NumericFormat } from 'react-number-format'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleRight,
  faCircleDollar,
  faFolder,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Colors, Sizes } from '@/components/types'

export interface OptionCard {
  circleColor?: Colors | string
  iconSize?: Sizes
  circleSize?: Sizes
  icon?: IconProp | string
  image?: string
  label: string
  value?: string
  detailsExpiration?: string
  fullwidth?: boolean
  angleRight?: boolean
}

const OptionCard = ({
  circleColor = 'lipa',
  iconSize = 'md',
  circleSize = 'md',
  image,
  icon,
  label,
  value,
  detailsExpiration,
  angleRight = true,
  fullwidth,
}: OptionCard) => {
  const [pressed, setPressed] = useState(false)

  const handleButtonClick = () => {
    setPressed(true)
    setTimeout(() => setPressed(false), 300)
  }
  return (
    <div
      onClick={handleButtonClick}
      className={clsx(
        'transition duration-500 ease-in-out',
        pressed ? 'scale-95' : 'scale-100'
      )}
    >
      <div
        className={clsx(
          fullwidth && 'w-full',
          'card shadow-card min-h-[64px] cursor-pointer'
        )}
      >
        <div className="flex h-full items-center justify-between gap-2 pl-2">
          <div className="flex w-full items-center gap-4">
            {icon && (
              <div
                className={clsx(
                  `option-card-circle-${circleColor}`,
                  `option-card-circle-${circleSize}`,
                  'flex items-center justify-center rounded-full'
                )}
              >
                <div className={clsx(`option-card-icon-${iconSize}`)}>
                  <FontAwesomeIcon
                    icon={((icon) => {
                      switch (icon) {
                        case 'faCircleDollar':
                          return faCircleDollar
                        case 'faFolder':
                          return faFolder
                        default:
                          return faFolder
                      }
                    })(icon)}
                  />
                </div>
              </div>
            )}
            {image && (
              <div
                className={clsx(
                  `option-card-circle-${circleColor}`,
                  `option-card-circle-${circleSize}`,
                  'flex items-center justify-center rounded-full'
                )}
              >
                <div className="flex items-center justify-center">
                  <Image
                    src={image}
                    alt={label}
                    width={150}
                    height={150}
                    className="aspect-[3/2] w-14 object-contain"
                  />
                </div>
              </div>
            )}
            {value && detailsExpiration ? (
              <>
                <div className="flex w-full flex-col gap-4 px-2">
                  <span className="text-base font-medium">{label}</span>
                  <div className="flex w-full justify-between">
                    <span className="text-lg font-bold">
                      Valor:{' '}
                      <NumericFormat
                        value={value}
                        displayType="text"
                        thousandSeparator={true}
                        prefix={'$ '}
                        decimalScale={2}
                        allowNegative={false}
                        className="text-lg font-bold"
                      />
                    </span>
                    <span className="text-base font-medium">
                      Vigencia: {detailsExpiration}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <span className="text-base font-medium">{label}</span>
            )}
          </div>
          {angleRight && (
            <div className="mr-5 w-3">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OptionCard
